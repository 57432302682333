<template>
  <div id="contacts" class="contacts">
    <div class="contacts__contacts">
      <div class="contacts__contacts-title">Contacts</div>
      <div class="contacts__contacts-items">
        <a
          v-for="{icon, text, url} in contacts"
          :key="url"
          :href="url"
          class="contacts__contacts-item"
        >
          <img :src="require(`@/assets/img/${icon}`)" alt="">
          <span>{{ text }}</span>
        </a>
      </div>
    </div>
    <form
      id="become-an-agent"
      class="contacts__form"
      @submit.prevent="sendForm"
    >
      <input
        v-for="{type, name, placeholder, required} in formFields"
        :key="name"
        v-model="formValues[name]"
        :type="type"
        :name="name"
        :required="required"
        :placeholder="`${placeholder}${required ? ' *' : ''}`"
        class="contacts__field"
      >
      <Button
        block
        type="submit"
        :button-text="submitText"
        class="contacts__submit"
        :class="{'contacts__submit--disabled': isRequestSending}"
      />
    </form>
  </div>
</template>

<script>
import { isProxy, toRaw } from 'vue'
import Button from '@/components/Button/index.vue'

export default {
  name: 'ContactForm',
  components: { Button },
  data () {
    return {
      contacts: [
        {
          icon: 'icons/icon-mail.svg',
          text: 'support@royalagent.cash',
          url: 'mailto:support@royalagent.cash'
        },
        {
          icon: 'icons/icon-telegram.svg',
          text: '@royalagent_cash',
          url: 'https://t.me/royalagent_cash'
        }
      ],
      formFields: [
        {
          type: 'text',
          name: 'name',
          placeholder: 'Name',
          required: true
        },
        {
          type: 'email',
          name: 'email',
          placeholder: 'E-mail',
          required: true
        },
        {
          type: 'tel',
          name: 'phone',
          placeholder: 'Phone',
          required: true
        },
        {
          type: 'text',
          name: 'telegram',
          placeholder: 'Telegram',
          required: false
        },
        {
          type: 'text',
          name: 'whatsapp',
          placeholder: 'Whatsapp',
          required: false
        }
      ],
      formValues: {
        name: '',
        email: '',
        phone: '',
        telegram: '',
        whatsapp: ''
      },
      isRequestSending: false
    }
  },
  methods: {
    getFormData () {
      if (isProxy(this.formValues)) {
        return toRaw(this.formValues)
      }
      return this.formValues
    },
    async sendPostRequest (data) {
      this.isRequestSending = true

      fetch('/mail.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify(data)
      })
        .then(response => {
          if (response.status === 200 || response.ok) {
            this.isRequestSending = false
            this.$emit('open-request-result-modal', true)
          }
          if (response.status === 404 || response.status === 500 || !response.ok) {
            this.isRequestSending = false
            this.$emit('open-request-result-modal', false)
          }
        })
        .catch(err => {
          if (err) {
            this.isRequestSending = false
            this.$emit('open-request-result-modal', false)
          }
        })
    },
    sendForm () {
      // eslint-disable-next-line
      send_data({ event: 'Click Event', button: 'send_form' })
      const data = this.getFormData()

      this.sendPostRequest(data)
    }
  },
  computed: {
    submitText () {
      if (this.isRequestSending) {
        return 'Sending...'
      }

      return 'Send'
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
