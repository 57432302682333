<template>
  <footer class="footer">
    <div class="footer__logo">
      <img src="@/assets/img/logo.svg" alt="logo">
    </div>
    <Menu
      class="footer_menu"
    />
    <button
      type="button"
      class="footer__nav-link"
      @click.stop="$emit('togle-terms')"
    >
      Terms & Conditions
    </button>
    <div class="seotext">
      <h1>Earning Online with Royal Agent</h1>
      <p>Royal Agent account opportunities are here to revolutionise the way you can generate income in the digital age! As an international iGaming company, Royal Agent offers a unique chance to tap into the lucrative market of online casinos and betting brokers. Attract players, help them make deposits and withdraw funds to earn your Royal Agent commission!</p>
      <h2>Benefits of Being an Agent</h2>
      <p>As a trusted intermediary between players and the platform, you'll have the opportunity to maximise your online earnings by helping player accounts make payments or through creating your own agent network with our affiliate system:</p>
      <ol>
        <li>High Commission Rates: Royal Agent is designed to reward the hard work of agents the way they deserve! Earn up to 12% on each transaction, including deposits and withdrawals.</li>
        <li>Unlimited Earning Potential: Your Royal Agent salary is directly tied to your performance. The more deposits of players and withdrawals from users you process, the higher your income potential.</li>
        <li>Flexible Work Environment: As an online-based opportunity, you can work from anywhere with an internet connection. This flexibility allows you to balance your work with other commitments and lifestyle preferences.</li>
        <li>Security and Anonymity: Royal Agent prioritises your safety. Working as an agent is absolutely anonymous, ensuring your privacy is protected.</li>
      </ol>
      <h2>Why Choose Royal Agent? </h2>
      <p>In the competitive world of iGaming, there are myriads of other trustworthy agent networks such as Mobcash or Melbet. So, why choose Royal Agent?</p>
      <ol>
        <li>Higher Commission Rates: While platforms like Mobcash offer competitive rates, Royal Agent provides up
          to 12% commission on transactions made through the Royal Agent shop, outperforming many competitors in the
          market.
        </li>
        <li>Network of Partnered Casinos: We closely work with a wide net of online casinos, offering you plenty of
          marketing opportunities to invite players – from constant promotions to casino-specific bonus programs.
        </li>
        <li>Comprehensive Support: Our affiliate support team is dedicated to your success. We provide personalised
          assistance, ensuring you have the tools and knowledge to thrive.
        </li>
        <li>Flexible Payment Options: Our partnered casinos offer a wide range of ways to make payments, such as
          fiat deposits, online wallets, or even crypto.
        </li>
        <li>Utter Convenience: Our partnered casinos offer a wide range of payment methods, allowing you to easily replenish consumers accounts, process even big payouts and receive your earnings in a way that fits you!</li>
      </ol>
      <h2>What You Need to Work as a Royal Agent</h2>
      <p>Become partner, by completing a few essential steps:</p>
      <ul>
        <li>Apply by filling the Royal Agent Registration Form.</li>
        <li>Fill in your contact details, including your WhatsApp or Telegram account number</li>
        <li>Complete identity verification.</li>
        <li>We will need your valid ID, contact number and email, to name a few.</li>
        <li>Complete your Royal Agent login process and make a deposit to your account.</li>
        <li>You can use any of the provided payment methods to complete this step.</li>
        <li>App download: APK download for Android or installation for iOS is required to complete your Royal Agent sign up. Such flexibility is outstandingly convenient for any user of any device (for example, Mobcash sign up is only available for Android users).</li>
      </ul>
      <h2>How It Works</h2>
      <p>Royal Agent offers you three distinct ways to boost your online earnings:</p>
      <ol>
        <li>
          Accept deposits and process withdrawals of players.
          <ol type="a">
            <li>Earn up to 12% of the deposit and withdrawal turnover for both new and consumers accounts.</li>
          </ol>
        </li>
        <li>
          Make profit from a new player that becomes our customer.
          <ol type="a">
            <li>Get a percentage of your players’ losses as your commission.</li>
          </ol>
        </li>
        <li>
          Create a network of sub agents via your affiliate link.
          <ol type="a">
            <li>Complete your Royal Master Agent registration online and grow your network of achieving partners and wallet agents. Royal Master Agent number of affiliates is not limited.</li>
          </ol>
        </li>
      </ol>
      <h2>FAQ</h2>
      <details>
        <summary>Who can become a Royal Agent?</summary>
        <p>Any person who is at least 18 years old (or has reached the age of majority in their jurisdiction if it's higher than 18) can become a Royal Agent. You must agree to our Terms and Conditions and complete the Royal Agent sign up process.</p>
      </details>
      <details>
        <summary>How to become an agent in India?</summary>
        <p>You can complete your Royal Agent registration online and sign up as a Royal Agent. To do this, you will need to provide:</p>
        <ul>
          <li>- Your full name</li>
          <li>- A valid Royal Agent contact number</li>
          <li>- A valid e-mail address</li>
          <li>- Actual residential address</li>
          <li>- Passport/ID card/driving license details</li>
          <li>- Copies of identification documents</li>
          <li>- A selfie with your ID document</li>
        </ul>
        <p>In some cases, we may require video identification for additional security.</p>
      </details>
      <details>
        <summary>How to create an agent account?</summary>
        <p>Fill in the required details on our landing page, including your contact number, Royal Agent WhatsApp number, and Royal Agent Telegram username. Our managers will reach out to you to discuss the cooperation details. After that, all that’s left to do is pass the identity verification and start earning online!</p>
      </details>
      <details>
        <summary>Can I use my Royal Agent account for personal gambling?</summary>
        <p>As of 1 January 2023, new agents are not allowed to use their assigned funds to fund their own gaming accounts or make withdrawals. However, agents who went through Royal Agent registration before this date may continue their gaming activities without restrictions.</p>
      </details>
      <details>
        <summary>How does the Royal Agent commission structure work?</summary>
        <p>Your commission is based on the rates in effect at the time of your registration. You'll earn a percentage of the transactions you process, including deposits and withdrawals. Remember, Royal Agent does not directly pay you; your earnings come solely from the transactions you manage.</p>
      </details>
      <details>
        <summary>How does Royal Agent ensure security for agents?</summary>
        <p>We prioritise the safety and anonymity of our agents. We conduct initial and ongoing identity verification based on potential risk levels. All interactions between you and our platform occur through the secure official communication channels specified on our website. This level of security will encourage you and your sub agents to keep the affiliate link going.</p>
      </details>
      <details>
        <summary>Can I invite other people to work with me as a royal sub agent?</summary>
        <p>Yes, you can! After completing your Master registration, you can invite other people to join under you as a Royal Cash Agent. You can do so through your referral link. You'll earn additional commissions from agents partners turnover, creating a passive income stream.</p>
      </details>
      <details>
        <summary>How can I ensure I'm following the rules as a Royal Agent?</summary>
        <p>Always use the platform as intended, avoid any fraudulent activities, and maintain open communication with our support team. If you're ever unsure about a transaction or process, reach out to our affiliate support for guidance. Keep in mind that as a Royal Deposit Agent, you are prohibited from using the deposit of players to fund your gaming accounts or to facilitate withdrawals from users accounts.</p>
      </details>
      <details>
        <summary>How much can an agent earn?</summary>
        <p>While individual results may vary, dedicated Royal Agents have the potential to earn significant income. Your Royal Agent salary is directly tied to your effort and success in managing transactions, attracting players, and growing your agent network.
          As a Royal Agent, you're a part of a dynamic and rewarding program. By following our guidelines and leveraging the tools we provide, you can maximise your online earnings and build a successful career in the iGaming industry.
        </p>
      </details>
      <details>
        <summary>What payment methods are available for my earnings?</summary>
        <p>We offer a variety of payment options for receiving your commissions. The specific methods available will be discussed during your onboarding process. Our goal is to provide flexible and convenient ways for you to access your earnings.</p>
      </details>
    </div>
    <div class="footer__copyright">© 2024 Royal Agent. All rights reserved</div>
  </footer>
</template>

<script>
import Menu from '@/components/Menu'

export default {
  name: 'FooterComponent',
  components: {
    Menu
  }
}
</script>

<style lang="scss" src="./Footer.scss" />
