<template>
  <div class="terms">
    <h1 class="terms__title">Terms and Conditions</h1>
    <ul class="terms__list">
      <li class="terms__list-item">
        <h3 class="terms__subtitle">1. About the Project</h3>
        <ul class="terms__list">
          <li class="terms__list-item">1.1 Royal Agent (hereinafter referred to as Franchisor) is a project of ROYAL PARTNERS Company (hereinafter referred to as Company) aimed at attracting franchisees (hereinafter referred to as Agent) to accept cash from the Company's customers to make deposits to their game accounts on the Company's websites. The Agent, in turn, receives profit in the form of a percentage of the transactions made.</li>
          <li class="terms__list-item">1.2 The Franchisor does not pay any money to the Agent. The Agent receives its profit solely as a percentage of the completed transactions.</li>
          <li class="terms__list-item">1.3 In case of changes to these Rules, the Agents will be notified by appropriate announcements.</li>
        </ul>
      </li>
      <li class="terms__list-item">
        <h3 class="terms__subtitle">2. Key Terms</h3>
        <ul class="terms__sublist">
          <li class="terms__sublist-item">– Franchisor - Royal Agent project</li>
          <li class="terms__sublist-item">– Franchisee (agent) - partner-entrepreneur, owner of cashier(s)</li>
          <li class="terms__sublist-item">– Company - a betting company Royal Partners</li>
          <li class="terms__sublist-item">- Cashier - a place where the client's payment is made</li>
          <li class="terms__sublist-item">- Client is a user of the Company's websites</li>
        </ul>
      </li>
      <li class="terms__list-item">
        <h3 class="terms__subtitle">3. Basic Provisions/Restrictions</h3>
        <ul class="terms__list">
          <li class="terms__list-item">3.1 An Agent of the Franchisor may be a person who is at least 18 years old or has reached the age of majority in his/her jurisdiction (if this age is higher than 18 years) and who agrees to the rules proposed by the Franchisor.</li>
          <li class="terms__list-item">3.2 An Agent may not use its assigned funds to fund its own gaming account or make withdrawals. This rule applies to new agents as of 01/01/2023. Already registered agents may continue their gaming activities and use their cash accounts without any restrictions.</li>
          <li class="terms__list-item">3.3 The commission amount for completed transactions is based on the current rates in effect at the time of new agent registration.</li>
        </ul>
      </li>
      <li class="terms__list-item">
        <h3 class="terms__subtitle">4. Identification</h3>
        <ul class="terms__list">
          <li class="terms__list-item">4.1 In accordance with AML's internal policies, Franchisor shall conduct initial and ongoing identity verification of Agents based on the level of potential risk associated with each Agent.</li>
          <li class="terms__list-item">4.2 To register, Agent shall provide Franchisor with:</li>
          <ul class="terms__sublist">
            <li class="terms__sublist-item">– Full Name</li>
            <li class="terms__sublist-item">– A valid telephone number</li>
            <li class="terms__sublist-item">– A valid e-mail address</li>
            <li class="terms__sublist-item">- Actual residential address</li>
            <li class="terms__sublist-item">- Passport/ID card/driving license or other details of a replacement document</li>
            <li class="terms__sublist-item">- Copies of the following documents: passport (main page) or identity card (both sides) or driver's license (both sides) or other substitute document that meets the following requirements: contains the name, date of birth and photograph of the document holder; has been issued by national government authorities</li>
            <li class="terms__sublist-item">- Selfies with the front side of the identity document</li>
          </ul>
          <li class="terms__list-item">4.3 In case of doubt as to the identity of the Agent, the Franchisor shall have the right to conduct a video identification procedure.</li>
        </ul>
      </li>
      <li class="terms__list-item">
        <h3 class="terms__subtitle">5. Agent's Work</h3>
        <ul class="terms__list">
          <li class="terms__list-item">5.1 The purpose of the Agent's work is to generate income from commission on transactions (deposit/withdrawal of funds) made by customers of the Company's websites.</li>
          <li class="terms__list-item">5.2 If Agent is suspected of committing fraudulent acts against the Company or is associated with customers who commit fraudulent acts against the Company (falsification of payment vouchers, multiple accounts, bets done by third party, use of automated betting software, gambling on arbitrage situations, placing bets that exceed limits set by the Company, abuse of loyalty programs etc.), Franchisor reserves the right to stop these fraudulent acts:</li>
          <ul class="terms__sublist">
            <li class="terms__sublist-item">– warning and non-payment of cashier fees,</li>
            <li class="terms__sublist-item">– blocking the Agent's cashier without refund in case of repeated violations,</li>
            <li class="terms__sublist-item">– blocking the Agent's cashier without warning if the Agent has caused significant damage to the Company as a result of the fraudulent actions.</li>
          </ul>
          <li class="terms__list-item">5.3 The Agent is only responsible for compliance with the applicable rules and is not responsible for customers violating the rules of the Company's websites. If the Company freezes/unfreezes the accounts of players who have funded their game accounts through the Agent, the Company may notify the Agent of the identifiers of those players.</li>
          <li class="terms__list-item">5.4 The Franchisor reserves the right to suspend any Cashier Agent in case of doubts about the Agent's integrity. The suspension shall remain in effect until the Company's security check is completed. During the verification process, video identification may be performed at a time agreed upon with the Agent. In case of repeated failure of video identification (failure to complete video identification within 7 calendar days due to the Agent's fault will be considered a refusal), the Agent's account will be blocked until video identification is performed.</li>
          <li class="terms__list-item">5.5 If the security service finds a violation of the rules on the part of the Agent, the Agent's account will be blocked with an explanation of the types of violations and the funds in the Agent's cash register will not be returned.</li>
          <li class="terms__list-item">5.6 The Company does not provide the Agent with any information about the clients who use its cashiers' services, except for the status: "frozen" or "active".</li>
          <li class="terms__list-item">5.7 Any interaction between the Agent and the Franchisor shall only take place through the official communication channels specified on the project website.</li>
        </ul>
      </li>
      <li class="terms__list-item">
        <h3 class="terms__subtitle">6. Technical Problems in the Application</h3>
        <ul class="terms__list">
          <li class="terms__list-item">6.1 In case of technical problems and system failures on the part of the Franchisor, there may be temporary difficulties in logging into the Application and performing cash transactions on the part of the Agent. The time for depositing and withdrawing funds may be extended up to 12 hours. In this case, the Agent will be notified via private message in the preferred method of communication.</li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions'
}
</script>

<style lang="scss" src="./style.scss" />
