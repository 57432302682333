<template>
  <div id="aboutus" class="advantages">
    <div
      v-for="{icon, title, desc} in advantages"
      :key="title"
      class="advantages__item"
    >
      <div class="advantages__item-icon">
        <img :src="require(`@/assets/img/${icon}`)" alt="icon">
      </div>
      <div class="advantages__item-text">
        <div class="advantages__item-title">{{ title }}</div>
        <div class="advantages__item-desc">{{ desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvantagesComponent',
  data () {
    return {
      advantages: [
        {
          icon: 'icons/icon-location.svg',
          title: 'Work from anywhere in the world',
          desc: 'Work from anywhere - all you need is a phone and internet access.'
        },
        {
          icon: 'icons/icon-earn.svg',
          title: 'Earn Commission',
          desc: 'No limits. Bring in players, make deposits and withdrawals for them, and earn commission.'
        },
        {
          icon: 'icons/icon-lock.svg',
          title: 'Anonymity and Security',
          desc: 'We care about your safety. Working as an agent is completely anonymous.'
        },
        {
          icon: 'icons/icon-team.svg',
          title: 'Build a Team',
          desc: 'Develop your agent network and earn passive commission from their turnover of the referrals brought in.'
        }
      ]
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
