<template>
  <div class="request-result">
    <img :src="require(`@/assets/img/icons/${imgName}`)" class="request-result_img" alt="">
    <p
      class="request-result_text"
      v-html="text"
    />
    <Button
      buttonText="OK"
      @click.stop="$emit('close')"
    />
  </div>
</template>

<script>
import Button from '@/components/Button/index.vue'

export default {
  name: 'RequestResult',
  components: {
    Button
  },
  props: {
    requestResult: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imgName () {
      return this.requestResult ? 'request-result-good.svg' : 'request-result-bad.svg'
    },
    text () {
      return this.requestResult ? 'Your request<br>has been submitted.' : 'An error occurred while<br>sending the request'
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
