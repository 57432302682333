<template>
  <div class="htb">
    <div class="htb__title">How to become an agent of Royal Agent?</div>
    <div class="htb__steps">
      <div
        v-for="{step, desc} in steps"
        :key="step"
        class="htb__step"
      >
        <div class="htb__item-title">{{ step }}</div>
        <div class="htb__item-desc">{{ desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowToBecomeComponent',
  data () {
    return {
      steps: [
        {
          step: '01',
          desc: 'Submit an application to create an agent account or contact a manager in the "Contacts" section on the website.'
        },
        {
          step: '02',
          desc: 'Discuss cooperation terms with the manager.'
        },
        {
          step: '03',
          desc: 'Receive login details for Royal Agent and start earning!'
        }
      ]
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
