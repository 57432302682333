<template>
  <div class="projects">
    <img
      v-for="{ name, imgName } in logotypes"
      :key="name"
      :src="require(`@/assets/img/projects/${imgName}`)" alt="name"
      class="projects__logo"
    >
  </div>
</template>
<script>
const LOGOTYPES = [
  {
    name: 'lex',
    imgName: 'lex.svg'
  },
  {
    name: '1go',
    imgName: '1go.svg'
  },
  {
    name: 'monro',
    imgName: 'monro.svg'
  },
  {
    name: 'drip',
    imgName: 'drip.svg'
  },
  {
    name: 'starda',
    imgName: 'starda.svg'
  },
  {
    name: 'legzo',
    imgName: 'legzo.svg'
  },
  {
    name: 'izzi',
    imgName: 'izzi.svg'
  },
  {
    name: 'jet',
    imgName: 'jet.svg'
  },
  {
    name: 'fresh',
    imgName: 'fresh.svg'
  },
  {
    name: 'sol',
    imgName: 'sol.svg'
  }
]

export default {
  name: 'ProjectsComponent',
  created () {
    this.logotypes = LOGOTYPES
  }
}
</script>

<style lang="scss" src="./style.scss"></style>
