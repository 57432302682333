<template>
  <div
    class="landing"
    :class="{ 'landing_modal-opened': isModalOpened }"
  >
    <Header @togle-terms="openTerms" />
    <main class="main">
      <div class="container">
        <Hero />
        <Advantages />
        <HowToWork />
        <HowToBecome />
        <Contacts @open-request-result-modal="openRequestResultModal" />
      </div>
      <ProjectsComponent class="landing__projects"/>
      <ModalShadow
        v-if="isModalOpened"
        :class="{'modal--terms-and-conditions': isTermsVisible}"
        @close="closeModal"
      >
        <template #body>
          <TermsAndConditions v-if="isTermsVisible" />
          <RequestResult
            v-if="isRequestResultModalVisible"
            :request-result="requestResult"
            @close="closeModal"
          />
        </template>
      </ModalShadow>
    </main>
    <div class="container">
      <FooterBlock @togle-terms="openTerms" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Hero from '@/components/Hero'
import Advantages from '@/components/Advantages'
import FooterBlock from '@/components/Footer/Footer.vue'
import HowToWork from '@/components/HowToWork'
import HowToBecome from '@/components/HowToBecome/index.vue'
import Contacts from '@/components/Contacts'
import ModalShadow from '@/components/ModalShadow/ModalShadow.vue'
import TermsAndConditions from '@/components/TermsAndConditions'
import RequestResult from '@/components/RequestResult'
import ProjectsComponent from '@/components/Projects'

export default {
  name: 'LandingComponent',
  data: () => {
    return {
      isRequestResultModalVisible: false,
      requestResult: false,
      openedVideo: null,
      showAll: false,
      isTermsVisible: false
    }
  },
  components: {
    ProjectsComponent,
    RequestResult,
    Contacts,
    HowToBecome,
    HowToWork,
    Hero,
    Advantages,
    Header,
    FooterBlock,
    ModalShadow,
    TermsAndConditions
  },
  methods: {
    openTerms () {
      this.isTermsVisible = true
      this.showModal = true
    },
    openRequestResultModal (result) {
      this.requestResult = result
      this.isRequestResultModalVisible = true
    },
    closeModal () {
      this.isTermsVisible = false
      this.isRequestResultModalVisible = false
    }
  },
  computed: {
    isModalOpened () {
      return this.isRequestResultModalVisible || this.isTermsVisible
    }
  }
}
</script>

<style lang="scss" src="./style.scss"></style>
