<template>
  <button
    class="button"
    :class="{ 'button_block': block }"
    :type="type"
  >
    <div class="button__bottom-gradient" />
    <span class="button__text">{{ buttonText }}</span>
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    block: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
