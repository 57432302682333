<template>
  <header class="header">
    <div class="header__top-block">
      <div class="container">
        <div class="header__logo-container">
          <img src="@/assets/img/logo.svg" alt="logo" class="header__logo">
        </div>
        <a href="/#become-an-agent">
          <Button
            @click="buttonClickHandler"
            button-text="Become an agent"
          />
        </a>
      </div>
    </div>
    <div class="header__nav">
      <div class="container">
        <nav class="header__menu">
          <Menu @togle-terms="$emit('togle-terms')" />
        </nav>
        <div class="header__social">
          <a href="https://t.me/royalagent_cash"><img src="../../assets/img/icons/icon-telegram.svg" alt=""></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Menu from '@/components/Menu'
import Button from '@/components/Button'

export default {
  name: 'HeaderComponent',
  components: {
    Button,
    Menu
  },
  methods: {
    buttonClickHandler () {
      // eslint-disable-next-line
      send_data({ event: 'Click Event', 'button': 'become_agent', 'place': 'Header' })
    }
  }
}
</script>

<style lang="scss" src="./style.scss"></style>
