<template>
  <div class="htw">
    <div class="htw__man">
      <picture class="htw__man-img">
        <source srcset="@/assets/img/businessman2.webp" media="(min-width: 1024px)" />
        <img src="@/assets/img/businessman2-mobile.webp" alt="man">
      </picture>
    </div>
    <div class="htw__texts">
      <div class="htw__title">How to work as an agent?</div>
      <div class="htw__text">
        The agent earns up to 12% of transactions for assisting in depositing/withdrawing funds for
        attracted or existing players. In addition to this, the agent can also earn a percentage of players' losses.
        Working as an agent in our company also provides an opportunity to receive passive income - attract agents
        through the referral program and receive a percentage of their turnover to your balance.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowToWorkComponent'
}
</script>

<style lang="scss" src="./style.scss" />
