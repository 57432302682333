<template>
  <Landing />
</template>

<script>
import Landing from '@/components/Landing'

export default {
  name: 'App',
  components: {
    Landing
  }
}
</script>
