<template>
  <div class="hero">
    <div class="hero__man">
      <picture class="hero__man-img">
        <source srcset="@/assets/img/businessman.webp" media="(min-width: 1024px)" />
        <img src="@/assets/img/businessman-mobile.webp" alt="man" />
      </picture>
    </div>
    <h1 class="hero__title">Make money with Royal Agent</h1>
    <p class="hero__text">
      Start cooperating with an international iGaming company. Top up accounts, withdraw funds to players,
      and earn commission up to 12% of transactions! Or create your own agency network and earn referral
      commission from the turnover of your agents!
    </p>
    <a href="/#become-an-agent">
      <Button
        @click="buttonClickHandler"
        button-text="Become an agent"
        class="hero__button"
      />
    </a>
  </div>
</template>

<script>
import Button from '@/components/Button/index.vue'

export default {
  name: 'HeroComponent',
  components: {
    Button
  },
  methods: {
    buttonClickHandler () {
      // eslint-disable-next-line
      send_data({ event: 'Click Event', 'button': 'become_agent', 'place': 'Main' })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
