<template>
  <nav class="menu">
    <a href="#aboutus" class="menu__item menu__item--about">About us</a>
    <a href="#terms" @click.stop="$emit('togle-terms')" class="menu__item menu__item--terms">Terms</a>
    <a href="#contacts" class="menu__item menu__item--contacts">Contacts</a>
  </nav>
</template>

<script>
export default {
  name: 'MenuComponent'
}
</script>

<style lang="scss" src="./style.scss" />
